<template>
  <div class="container">
    <div class="bgimg">
      <div class="info">
        <img class="avatar" :src="avatarUrl" alt="">
        <div class="right">
          <div class="nickname">{{ nickname }}</div>
          <div class="desc">邀你一起参加</div>
        </div>
      </div>
      <div class="qrcode-box">
        <img class="qrcode" :src="qrcode" alt="">
      </div>
    </div>
  </div> 
</template>

<script>
import $fetch from '@/utils/fetch.js';
export default {
  data() {
    return {
      auth: "",
      routeId: "",
      teamId: "",
      avatarUrl: "",
      nickname: "",
      qrcode: "",
    }
  },
  async created() {
    let { auth, routeId, teamId, avatarUrl, nickname } = this.$route.query;
    this.auth = auth;
    this.routeId = routeId;
    this.teamId = teamId;
    this.avatarUrl = avatarUrl;
    this.nickname = nickname;
    this.getWechatCode();
  },
  methods: {
    /** 参数缩短 */ 
    async handleShortenLink() {
      const { auth, routeId, teamId } = this;
      const { result }  = await $fetch.fetch('xx.cumen.equip.v1.EquipService/ShortenString',
        { action: 1, val: `?routeId=${ routeId }&teamId=${ teamId }`},
        { Authorization: auth }
      );
      return result;
    },
    /** 获取活动的小程序码 */
    async getWechatCode() {
      const _ = this;
      const shortLink = await this.handleShortenLink();
      $fetch
        .post("GetWeixinQRCode", {
          page: `packages/challenge/step/pages/index`,
          scene: shortLink,
          width: 80,
          is_hyaline: true,
        })
        .then((code) => {
          _.qrcode = "data:image/jpeg;base64, " + code.image;
        });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
$multipleCount: 1.1467;

.container {
  @include column(flex-start, center);
  position: relative;
  width: 100vw;
  height: 126.699vw;

  .bgimg {
    width: 100%;
    height: 100%;
    background: url(https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01912bab-f3f1-7bd2-6f31-bc083451d505) no-repeat left top / cover;

    .info {
      @include row;
      width: 100%;
      height: px2rem(96 * $multipleCount);
      padding: 0 px2rem(60 * $multipleCount);
      margin-top: px2rem(40 * $multipleCount);
      box-sizing: border-box;

      .avatar {
        width: px2rem(96 * $multipleCount);
        height: px2rem(96 * $multipleCount);
        border-radius: 50%;
      }

      .right {
        @include column(space-between, flex-start);
        height: 100%;
        margin-left: px2rem(16 * $multipleCount);
        overflow: hidden;

        .nickname {
          @include ellipsis;
          width: 100%;
          color: #fff;
          font-size: px2rem(36 * $multipleCount); 
          font-weight: bold;
        }

        .desc {
          color: #fff;
          font-size: px2rem(28 * $multipleCount);
          font-weight: bold;
        }
      }
    }

    .qrcode-box {
      position: absolute;
      right: px2rem(20 * $multipleCount);
      bottom: px2rem(20 * $multipleCount);
      width: px2rem(122 * $multipleCount);
      height: px2rem(120 * $multipleCount);
      background-color: #fff;
      border-radius: px2rem(6 * $multipleCount);
      
      .qrcode {
        width: 100%;
        height: 100%;
        padding: px2rem(4 * $multipleCount);
        box-sizing: border-box;
      }
    }
  }
}
</style>
